<template>
  <div
    component-name="toolbar-grid-products"
    class="overflow-clip flex justify-center h-min w-full px-edge"
  >
    <btn
      title="variants"
      data-skin="black"
      class="overflow-hidden flex justify-between items-center gap-3xl bg-black rounded-lg text-skin-base w-full px-2xl h-[72px] will-change-[width] transition-[width] hover:w-full duration-500 hover:duration-700 ease-in-out"
      :class="{
        'w-full': isOpen,
        'w-11/12 phablet:w-10/12 laptop:w-2/5': !isOpen,
      }"
      @click="isOpen = true"
    >
      <span
        class="text-body-big/none truncate leading-none whitespace-nowrap"
        >{{ $t.labels.colors_and_variants }}</span
      >

      <span class="inline-flex gap-xs items-center">
        <span class="text-body/none">{{ totalItems }}</span>
        <svg-icon size="big" icon="thumbs-grid" />
      </span>
    </btn>

    <modal-grid-products
      :is-open="isOpen"
      :products="products"
      @close="isOpen = false"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  products: {
    type: Array,
    required: true,
  },
});

const isOpen = ref(false);

const totalItems = computed(() => {
  let count = 0;

  for (let i = 0, l = props.products.length; i < l; i++) {
    count += props.products[i].items.length;
  }

  return count;
});
</script>
