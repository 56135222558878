<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-grid-products"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="fixed inset-0 bg-black/50"
            aria-hidden="true"
            @click="$emit('close')"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex flex-col w-full h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                ref="tpl_scrollable"
                class="pointer-events-auto overflow-auto overscroll-contain w-full h-screen max-h-screen pb-[calc(100lvh-100svh)] !m-0 !p-0 bg-skin-base"
              >
                <div class="flex flex-col w-full">
                  <!-- HEADER -->
                  <header
                    class="sticky z-[2] top-0 left-0 min-h-min bg-skin-base"
                  >
                    <toolbar-modal-header @close="$emit('close')">
                      <template #header-left>
                        <span
                          class="text-body-big/none laptop:text-heading-6/none truncate leading-none whitespace-nowrap"
                          >{{ $t.labels.colors_and_variants }}</span
                        >
                      </template>
                    </toolbar-modal-header>
                    <toolbar-tabs
                      v-if="products.length > 1"
                      ref="tpl_toolbar"
                      :items="products"
                      @update="handleScroll"
                      :inert="disableToolbarTabs || undefined"
                    />
                  </header>
                  <!-- end HEADER -->

                  <!-- GRID -->
                  <div class="relative top-0 left-0 w-full h-full max-h-full">
                    <div data-skin="white" class="w-full p-edge">
                      <div
                        ref="tpl_scrollWrapper"
                        class="flex flex-col gap-4xl"
                      >
                        <div
                          v-for="(variant, index) in products"
                          class="flex flex-col gap-3xl w-full"
                          :data-scroll-target="index"
                        >
                          <txt-heading
                            size="small"
                            :title="variant.title"
                            :force-divider="true"
                          />
                          <grid-variants
                            :items="variant.items"
                            :use-link-exact-active="true"
                            loading="lazy"
                            class="w-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end GRID -->
                </div>
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  products: {
    type: Array,
    required: true,
  },
});

const disableToolbarTabs = ref(false);
const tpl_scrollWrapper = templateRef("tpl_scrollWrapper");
const tpl_scrollable = templateRef("tpl_scrollable");
const emit = defineEmits(["close"]);
const tpl_toolbar = templateRef("tpl_toolbar");
const route = useRoute();
const nuxtApp = useNuxtApp();

watch(
  () => route.fullPath,
  (newVal, oldVal) => {
    emitClose();
  }
);

function emitClose() {
  emit("close");
}

function determineCurrentIndex() {
  if (disableToolbarTabs.value) {
    return;
  }

  // get if the modal has scrolled completely to the bottom
  if (
    tpl_scrollable.value.el.scrollTop + tpl_scrollable.value.el.clientHeight ===
    tpl_scrollable.value.el.scrollHeight
  ) {
    tpl_toolbar.value.setCurrentIndex(
      tpl_scrollWrapper.value.children.length - 1
    );
    return;
  }

  let inView;
  for (let i = 0; i < tpl_scrollWrapper.value.children.length; i++) {
    const $el = tpl_scrollWrapper.value.children[i];
    const rect = $el.getBoundingClientRect();

    if (
      (rect.top >= 0 && rect.top <= window.innerHeight) ||
      (rect.bottom >= 0 && rect.bottom <= window.innerHeight) ||
      (rect.top <= 0 && rect.bottom >= window.innerHeight)
    ) {
      let intersectionHeight;
      if (rect.top <= window.innerHeight && rect.bottom >= window.innerHeight) {
        intersectionHeight = window.innerHeight - rect.top;
      } else {
        intersectionHeight = rect.bottom;
      }

      if (!inView || inView.intersectionHeight < intersectionHeight) {
        inView = {
          index: i,
          intersectionHeight,
        };
      }
    }
  }
  tpl_toolbar.value.setCurrentIndex(inView?.index || 0);
}

function watchScroll(value) {
  if (!tpl_scrollable.value?.el) {
    return;
  }
  tpl_scrollable.value.el.removeEventListener("scroll", determineCurrentIndex);
  if (value) {
    tpl_scrollable.value.el.addEventListener("scroll", determineCurrentIndex);
  }
}

onMounted(() => {
  window.addEventListener("reload-same-page", emitClose);
  watch(
    () => props.isOpen,
    (newVal) => {
      if (newVal) {
        nextTick(() => {
          watchScroll(newVal);
        });
      } else {
        watchScroll(newVal);
      }
    }
  );
});

onBeforeUnmount(() => {
  window.removeEventListener("reload-same-page", emitClose);
  watchScroll(false);
});

function handleScroll(index) {
  disableToolbarTabs.value = true;

  const $el = tpl_scrollWrapper.value.children[index];

  const scrollable = nuxtApp.$device.isMobile
    ? tpl_scrollable.value
    : tpl_scrollable.value.el;

  const scrollableRect = scrollable.children[0].getBoundingClientRect();

  gsap.to(scrollable, {
    duration: 0.7,
    scrollTo: {
      y: $el,
      offsetY:
        document
          .querySelector("[data-scroll-target='0']")
          .getBoundingClientRect().top - scrollableRect.top,
    },
    ease: "power2.inOut",
    onComplete: () => {
      disableToolbarTabs.value = false;
    },
  });
}
</script>
