<template>
  <div component-name="app-page" class="w-full min-h-screen bg-skin-base">
    <!-- HEADER (spacer) -->
    <header
      v-if="body?.config?.pt !== 'none' && useHeaderSpacer"
      class="w-full invisible"
      :class="{
        'pt-3xl laptop:pt-4xl':
          !body?.config?.pt || body?.config?.pt === 'regular',
        'pt-2xl laptop:pt-3xl': body?.config?.pt === 'snug',
        'pt-xl laptop:pt-2xl': body?.config?.pt === 'tight',
      }"
      aria-hidden="true"
    >
      <div class="h-40 w-full"></div>
    </header>
    <!-- end HEADER (spacer) -->

    <!-- BODY -->
    <div class="flex flex-col items-center w-full">
      <!-- CONTENT -->
      <div
        class="relative top-0 left-0 z-[1] w-full"
        :class="{
          'pb-2xl': body?.config?.pb && subpages && subpageActive,
          'pb-5xl':
            body?.config?.pb && (!subpages || (subpages && !subpageActive)),
        }"
      >
        <composer
          v-if="body?.data?.wrappers?.length"
          :wrappers="body.data.wrappers"
        />
        <slot v-else />
      </div>
      <!-- end CONTENT -->

      <!-- SUBPAGES -->
      <div
        v-if="subpages"
        class="flex flex-col items-stretch gap-4xl w-full max-w-full min-h-screen"
        ref="tpl_subpage"
      >
        <block
          v-if="!subpages.hideTabsToolbar && subpages.items.length > 1"
          class="sticky top-to-app-header left-0 z-2"
          :px="false"
        >
          <toolbar-tabs :items="subpages.items" />
        </block>

        <nuxt-page
          :page-key="$route.name"
          :transition="{
            onLeave: onLeave,
            onAfterEnter: onAfterEnter,
          }"
        />
      </div>
      <!-- end SUBPAGES -->
    </div>
    <!-- end BODY -->

    <!-- FOOTER -->
    <footer
      v-if="body?.config?.useFooter !== false && !subpages"
      class="w-full"
    >
      <block-launcher-btn-list :use-component-meta="useBlockMeta" />
    </footer>
    <!-- end FOOTER -->
  </div>
</template>

<script setup>
const props = defineProps({
  head: {
    type: Object,
    required: false,
  },
  body: {
    type: Object,
    required: false,
  },
  subpages: {
    type: Object,
    required: false,
  },
  useHeaderSpacer: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const { setSeo } = useSeo();
const nuxtApp = useNuxtApp();
const { setAppInert } = useGlobals();
const useBlockMeta = computed(() => {
  return process.env.NODE_ENV === "development";
});
const route = useRoute();
const tpl_subpage = templateRef("tpl_subpage");
const subpageActive = computed(() => {
  if (!props.subpages?.items?.length) {
    return false;
  }
  for (let i = 0; i < props.subpages.items.length; i++) {
    const item = props.subpages.items[i];
    if (
      `/${route.params.country}/${route.params.locale}${item.action.to}` ==
      route.path
    ) {
      return true;
    }
  }
  return false;
});

function setSkin() {
  useHead({
    htmlAttrs: {
      "data-skin": props.body?.config?.skin ? props.body.config.skin : "white",
    },
  });
}

function onLeave(el, done) {
  setAppInert(false);

  // get lenis scroll y position
  if (window.scrollY > 0) {
    nuxtApp.$lenis.scrollTo(0, {
      force: true,
      lock: true,
      offsetY: -1,
      duration: 0.5,
      easing: gsap.parseEase("power2.out"),
      onComplete: () => {
        done();
      },
    });
  } else {
    done();
  }
}

function onAfterEnter() {
  setAppInert(false);
  ScrollTrigger.refresh(true);
}

watch(() => props.body, setSkin);

setSkin();
setSeo(props.head);
</script>
