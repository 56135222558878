<template>
  <div component-name="composer" class="flex flex-col gap-5xl">
    <wrapper-blocks
      v-for="(wrapper, index) in finalWrappers"
      :config="wrapper.config ? wrapper.config : undefined"
      :data="wrapper.data ? wrapper.data : undefined"
      @updateTabIndex="updateTabIndex(index, $event)"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  wrappers: {
    type: Object,
    required: true,
  },
});

const initialDataModel = ref({ ...props.wrappers });

const finalWrappers = computed(() => {
  let body = { ...initialDataModel.value };

  if (body.length) {
    let counter = 0;
    let currentTab;

    // for wrappers
    for (let i = 0, len = wrappers.length; i < len; i++) {
      currentTab = body[i].config.currentTabIndex;

      // for blocks in tab
      for (
        let ind = 0, l = body[i].tabs[currentTab].blocks.length;
        ind < l;
        ind++
      ) {
        counter++;

        // lazy
        body[i].tabs[currentTab].blocks[ind].config.lazyComponent = counter > 2;
        body[i].tabs[currentTab].blocks[ind].config.lazyLoading = counter > 2;

        // Global Block index (in page or modal)
        body[i].tabs[currentTab].blocks[ind].config.globalBlockCounter =
          counter;
      }
    }
  }
  return body;
});

function updateTabIndex(wrapperIndex, tabIndex) {
  initialDataModel.value[wrapperIndex].config.currentTabIndex = tabIndex;

  ScrollTrigger.refresh(true);
}
</script>
