import { useRootStore } from "@/stores/root";
import { useCdn } from "/composables/useCdn";

export const useSeo = () => {
  // Create a Cloudinary instance and set your cloud name.

  function setSeo(head) {
    const route = useRoute();
    const rootStore = useRootStore();
    const nuxtApp = useNuxtApp();
    const { fromCloudinary, fromThron } = useCdn();

    if (head.title) {
      // remove trailing slash if present
      const path = route.path.replace(/\/$/, "");

      const meta = [];
      let description = "";

      if (head.meta && head.meta.length) {
        const findImgWidth = head.meta.find(
          (item) =>
            item.property == "og:image:width" || item.name == "og:image:width"
        );

        const findImgHeight = head.meta.find(
          (item) =>
            item.property == "og:image:height" || item.name == "og:image:height"
        );

        const shareImg = head.share_img.asset;
        let shareImgUrl = "";
        if (shareImg.cdn == "cloudinary") {
          shareImgUrl = fromCloudinary(shareImg.origin_id, {
            width: findImgWidth?.content || 1200,
            height: findImgHeight?.content || 630,
          });
        } else {
          shareImgUrl = fromThron({
            contentId: shareImg.origin_id,
            prettyName: shareImg.pretty_name || shareImg.origin_id,
            divArea: {
              width: findImgWidth?.content || 1200,
              height: findImgHeight?.content || 630,
            },
          });
        }

        for (let i = 0; i < head.meta.length; i++) {
          const hid = head.meta[i].name ? "name" : "property";
          const metaObj = {
            hid: head.meta[i][hid],
            [hid]: head.meta[i][hid],
            content: head.meta[i].content,
          };
          if (
            head.meta[i][hid] == "og:image" ||
            head.meta[i][hid] == "image" ||
            head.meta[i][hid] == "twitter:image"
          ) {
            metaObj.content = shareImgUrl;
          }
          meta.push(metaObj);
        }

        const findDesc = head.meta.find((item) => item.name == "description");
        if (findDesc && findDesc.content) {
          description = findDesc.content;
        }
      }

      const scripts = [];
      if (head.script) {
        for (let i = 0; i < head.script.length; i++) {
          if (head.script[i].type == "application/ld+json") {
            scripts.push({
              type: "application/ld+json",
              innerHTML: JSON.stringify(head.script[i].inner_content),
            });
          } else {
            scripts.push(head.script[i]);
          }
        }
      }

      const link = [
        {
          hid: "canonical",
          rel: "canonical",
          href: `https://www.mirage.it${path}`,
        },
      ];

      let mrgCountry = useCookie("mrg_country", {
        maxAge: 60 * 60 * 24 * 365,
      });

      function getLanguages() {
        for (let continent of rootStore.ui.modal.menu.locale.items) {
          for (let country of continent.countries) {
            if (
              country?.value?.toLowerCase() == mrgCountry.value?.toLowerCase()
            ) {
              return country.items.map((item) => item.title);
            }
          }
        }
      }

      const langs = getLanguages();
      if (langs) {
        for (let i = 0; i < langs.length; i++) {
          link.push({
            rel: "alternate",
            hreflang: langs[i],
            href:
              `https://www.mirage.it` +
              path.replace(
                `/${route.params.country}/${route.params.locale}`,
                `/${route.params.country}/${langs[i]}`
              ),
          });

          meta.push({
            property: "og:locale:alternate",
            content: langs[i],
          });
        }
        link.push({
          rel: "alternate",
          hreflang: "x-default",
          href:
            `https://www.mirage.it` +
            path.replace(
              `/${route.params.country}/${route.params.locale}`,
              `/${route.params.country}/${langs[0]}`
            ),
        });
      }

      if (head.link) {
        for (let i = 0; i < head.link.length; i++) {
          link.push(head.link[i]);
        }
      }

      const obj = {
        title: `${head.title}${nuxtApp.$t.labels.brand_suffix}`,
        description: description,
        htmlAttrs: {
          lang: route.params.locale,
        },
        meta: meta,
        link: link,
        script: scripts,
      };

      useHead(obj);
    } else {
      console.warn("Seo is missing");
    }
  }

  return {
    setSeo,
  };
};
