<template>
  <header
    component-name="toolbar-tabs"
    class="flex flex-col items-center justify-end max-w-full text-skin-base"
    ref="tpl_header"
  >
    <wrapper-reveal
      ref="tpl_wrapper"
      reveal="fade-from-down"
      class="flex h-16 max-w-full overflow-x-auto"
      :class="{
        'justify-center': wrapperW > contentW,
        'justify-start': wrapperW <= contentW,
      }"
    >
      <div ref="tpl_content" class="flex items-baseline gap-2xl px-2xl">
        <btn-tab
          v-for="(item, index) in items"
          class="h-full select-none"
          :title="item.title"
          :icon="item.icon"
          :action="item.action ? item.action : undefined"
          :pressed="!item.action?.to && pressedIndex === index"
          :use-tracking="
            item.action?.to
              ? {
                  event: 'click_voce_menu',
                  voce_menu: item.title,
                }
              : false
          "
          @click="handleClick(index, item.action?.to)"
        />
      </div>
    </wrapper-reveal>

    <div class="w-full px-edge">
      <divider class="w-full !origin-center" />
    </div>
  </header>
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  initial: {
    type: Number,
    required: false,
    default: 0,
  },
});

const router = useRouter();
const route = useRoute();
const pressedIndex = ref(props.initial);
const emit = defineEmits(["update"]);
const tpl_header = templateRef("tpl_header");
const tpl_wrapper = templateRef("tpl_wrapper");
const tpl_content = templateRef("tpl_content");

const wrapperW = ref(0);
const contentW = ref(0);

useSafeMountedEl([tpl_wrapper, tpl_content], () => {
  nextTick(() => {
    const tplWrapperW = useElementSize(tpl_wrapper);
    const tplContentW = useElementSize(tpl_content);
    wrapperW.value = tplWrapperW.width;
    contentW.value = tplContentW.width;
    watch([tplWrapperW.width, tplContentW.width], () => {
      wrapperW.value = tplWrapperW.width;
      contentW.value = tplContentW.width;
    });
  });
});

function handleClick(index, to) {
  if (to) return;
  pressedIndex.value = index;
}

const { pause, resume } = watchPausable(pressedIndex, (newVal) => {
  if (props.items[newVal].action?.to) {
    router.push(
      `/${route.params.country}/${route.params.locale}${props.items[newVal].action?.to}`
    );
  } else emit("update", newVal);
});

let timeout;
function setCurrentIndex(index) {
  pause();
  pressedIndex.value = index;
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    resume();
  }, 300);
}

// expose data and method thingy
defineExpose({
  setCurrentIndex,
});
</script>
