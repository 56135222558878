<template>
  <article
    component-name="wrapper-blocks"
    ref="tpl_root"
    :data-skin="config.skin ? config.skin : undefined"
    class="relative top-0 left-0 w-full bg-skin-base text-skin-base"
    :class="{
      'flex flex-col items-stretch': data.header,
      'gap-4xl': data.header && tabsMenu?.length < 2,
    }"
    :inert="isInert || undefined"
  >
    <!-- HEADER -->
    <header v-if="data.header" class="w-full px-edge">
      <txt-heading
        tag="div"
        :size="data.header.size || 'big'"
        :overline="data.header.overline || undefined"
        :title="data.header.title || undefined"
        :button="data.header.button || undefined"
        :force-divider="tabsMenu.length < 2"
        :atelier="data.header.atelier || undefined"
        :resource="data.header.resource || undefined"
        :text="data.header.text || undefined"
      />
    </header>
    <!-- end HEADER -->

    <div class="flex flex-col items-stretch gap-2xl w-full">
      <!-- TABS (visible only with 2 or more tabs) -->
      <div
        v-if="tabsMenu?.length > 1"
        class="sticky top-to-app-header left-0 z-[9] flex flex-col items-stretch"
      >
        <toolbar-tabs
          :initial="config.currentTabIndex"
          :items="tabsMenu"
          @update="handleTabIndex"
          class="w-full bg-skin-base"
        />
      </div>
      <!-- end TABS -->

      <!-- BODY -->
      <div
        v-if="data.tabs[config.currentTabIndex].blocks?.length"
        ref="tpl_content"
        class="flex flex-col items-stretch w-full gap-5xl relative top-0 left-0"
        :class="{
          'mt-4xl': (data.header && data.header?.data) || tabsMenu?.length > 1,
          'min-h-screen': !enabled,
          'pb-5xl': data.collectionOverlay?.length,
        }"
      >
        <div v-if="enabled" class="contents">
          <div
            v-if="data.collectionOverlay?.length"
            class="z-[8] absolute inset-0 overflow-clip w-full h-full pointer-events-none flex flex-col items-center justify-end"
          >
            <toolbar-grid-products
              :products="data.collectionOverlay"
              class="sticky bottom-lg left-0 pointer-events-auto"
            />
          </div>

          <div
            v-for="block in data.tabs[config.currentTabIndex].blocks"
            class="contents"
          >
            <component
              :is="`${
                block.config?.lazyComponent ? `lazy-${block.is}` : block.is
              }`"
              :config="block.config"
              :data="block.data"
              :loading="block.config?.lazyLoading ? 'lazy' : 'auto'"
            />
          </div>
        </div>
      </div>
      <!-- end BODY  -->

      <!-- DECLARATIVE MARKUP -->
      <slot v-else />
      <!-- end DECLARATIVE MARKUP -->
    </div>
  </article>
</template>

<script setup>
const props = defineProps({
  config: {
    type: Object,
    required: false,
  },
  data: {
    type: Object,
    required: false,
  },
});

const isInert = ref(false);
const enabled = ref(true);
const emit = defineEmits(["updateTabIndex"]);
const tpl_content = templateRef("tpl_content");
const tabsMenu = computed(() => {
  let list = [];

  if (props.data?.tabs?.length) {
    for (let i = 0, len = props.data.tabs.length; i < len; i++) {
      list.push({ title: props.data.tabs[i].title });
    }
  }

  return list;
});
const nuxtApp = useNuxtApp();
function handleTabIndex(tabIndex) {
  let tl = gsap.timeline({
    onStart: () => {
      isInert.value = true;

      nuxtApp.$lenis.scrollTo(tpl_content.value, {
        force: true,
        lock: true,
        duration: 0.45,
        easing: gsap.parseEase("circ.out"),
      });
    },
    onComplete: () => {
      isInert.value = false;
    },
  });

  tl.to(tpl_content.value, {
    duration: 0.45,
    autoAlpha: 0,
    ease: "expo.inOut",
    onComplete: () => {
      tl.pause();
      enabled.value = false;

      nextTick(() => {
        emit("updateTabIndex", tabIndex);
        nextTick(() => {
          enabled.value = true;
          nextTick(() => {
            ScrollTrigger.refresh();
            tl.resume();
          });
        });
      });
    },
  });

  tl.to(
    tpl_content.value,
    {
      duration: 0.55,
      autoAlpha: 1,
      ease: "expo.inOut",
    },
    "+=0.2"
  );
}
</script>
